import { GeneralErrorBoundary } from "@/components/error-boundary";
import { ClientHintCheck, getHints } from "@/lib/client-hints";
import { getEnv } from "@/lib/env.server";
import { honeypot } from "@/lib/honeypot.server";
import i18next from "@/lib/i18next.server";
import { useNonce } from "@/lib/nonce-provider";
import { useTheme } from "@/lib/theme";
import { getTheme } from "@/lib/theme.server";
import { combineHeaders, getDomainUrl } from "@/lib/utils";
import {
	type HeadersFunction,
	type LinksFunction,
	type LoaderFunctionArgs,
	json,
} from "@remix-run/node";
import {
	Links,
	Meta,
	Outlet,
	Scripts,
	ScrollRestoration,
	type ShouldRevalidateFunctionArgs,
	useLoaderData,
} from "@remix-run/react";
import { withSentry } from "@sentry/remix";
import { MotionConfig } from "framer-motion";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { getToast } from "remix-toast";
import { HoneypotProvider } from "remix-utils/honeypot/react";
import { Toaster, toast as notify } from "sonner";
import styles from "./styles/tailwind.css?url";

export const links: LinksFunction = () => {
	return [
		{ rel: "mask-icon", href: "/favicons/mask-icon.svg" },
		{
			rel: "alternate icon",
			type: "image/png",
			href: "/favicons/favicon-32x32.png",
		},
		{ rel: "apple-touch-icon", href: "/favicons/apple-touch-icon.png" },
		{ rel: "icon", type: "image/svg+xml", href: "/favicons/favicon.svg" },
		{ rel: "stylesheet", href: styles },
	].filter(Boolean);
};

export async function loader({ request }: LoaderFunctionArgs) {
	// let locale = await i18next.getLocale(request)
	const { toast, headers: toastHeaders } = await getToast(request);

	return json(
		{
			locale: await i18next.getLocale(request),
			requestInfo: {
				hints: getHints(request),
				origin: getDomainUrl(request),
				path: new URL(request.url).pathname,
				userPrefs: {
					theme: getTheme(request),
				},
			},
			toast,
			honeyProps: honeypot.getInputProps(),
			ENV: getEnv(),
		},
		{
			headers: combineHeaders(toastHeaders),
		},
	);
}

export const headers: HeadersFunction = ({ loaderHeaders }) => {
	const headers = {
		"Server-Timing": loaderHeaders.get("Server-Timing") ?? "",
	};
	return headers;
};

export const handle = {
	// In the handle export, we can add a i18n key with namespaces our route
	// will need to load. This key can be a single string or an array of strings.
	// TIP: In most cases, you should set this to your defaultNS from your i18n config
	// or if you did not set one, set it to the i18next default namespace "translation"
	i18n: "common",
};

function useChangeLanguage(locale: string) {
	const { i18n } = useTranslation();
	useEffect(() => {
		i18n.changeLanguage(locale);
	}, [locale, i18n]);
}

function App({ children }: { children: React.ReactNode }) {
	const { locale, toast } = useLoaderData<typeof loader>();

	const { i18n, t } = useTranslation();

	const nonce = useNonce();
	const theme = useTheme();
	useChangeLanguage(locale);

	useEffect(() => {
		if (toast?.type === "error") {
			notify.error(t(toast.message));
		}
		if (toast?.type === "success") {
			notify.success(t(toast.message));
		}
	}, [t, toast]);

	useEffect(() => {
		if ("serviceWorker" in navigator) {
			navigator.serviceWorker
				.getRegistrations()
				.then((registrations) => {
					for (const registration of registrations) {
						registration.unregister();
					}
				})
				.catch((err) => {
					console.log("Service Worker registration failed: ", err);
				});
		}

		localStorage.clear();
	}, []);

	return (
		<html
			lang={locale}
			className={`${theme} h-full antialiased`}
			dir={i18n.dir()}
		>
			<head>
				<ClientHintCheck nonce={nonce} />
				<meta charSet="utf-8" />
				<meta name="viewport" content="width=device-width, initial-scale=1" />
				<Meta />
				<Links />
			</head>
			<body className="h-full min-h-svh bg-background">
				<noscript>
					<div className="error text-xl">
						JavaScript is not enabled. Please enable JavaScript and reload the
						page.
					</div>
				</noscript>
				<MotionConfig reducedMotion="user" nonce={nonce}>
					{children}
				</MotionConfig>
				<EnvInjector nonce={nonce} />
				<ScrollRestoration nonce={nonce} />
				<Scripts nonce={nonce} />
				<Toaster richColors position="top-center" />
			</body>
		</html>
	);
}

function EnvInjector({ nonce }: { nonce: string }) {
	const { ENV } = useLoaderData<typeof loader>();
	return (
		<script
			nonce={nonce}
			suppressHydrationWarning
			dangerouslySetInnerHTML={{
				__html: `window.ENV = ${JSON.stringify(ENV)}`,
			}}
		/>
	);
}

function AppWithProviders() {
	const { honeyProps } = useLoaderData<typeof loader>();
	return (
		<App>
			<HoneypotProvider {...honeyProps}>
				<Outlet />
			</HoneypotProvider>
		</App>
	);
}

export default withSentry(AppWithProviders);

export function ErrorBoundary() {
	return <GeneralErrorBoundary />;
}

export function shouldRevalidate({ formMethod }: ShouldRevalidateFunctionArgs) {
	return (
		formMethod === "POST" || formMethod === "PATCH" || formMethod === "DELETE"
	);
}
